import React from "react";
import { useContext } from "react";
import { InputContext } from "../../../contexts";
import { Form, Row, Col } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import RevisionInfo from "../RevisionInfo";

const TextInput = ({
  fieldData,
  onChangeHandler,
  errors,
  field,
  displayItem,
  readonly,
  placeholder,
  unit,
  stringFormat,
  labelSubtext,
  fieldPostText,
  inputName,
  fieldID,
  preventSubmitOnEnter = false,
}) => {
  // Get our inputContext
  const { inputContext } = useContext(InputContext);

  // Determine whether this field should be displayed normally or inline
  const displayInline =
    inputContext == "inline" ||
    inputContext == "table" ||
    inputContext == "subform";

  const inlineField = inputContext == "inline";
  const tableOrSubformField =
    inputContext == "table" || inputContext == "subform";

  const fieldName = inputName;

  // Build our form label
  const formLabel = () => {
    if (displayItem.hideLabel) {
      return null;
    }

    let labelProps = {
      column: true,
      sm: displayInline ? "auto" : 5,
      className: `fw-bold pe-0 ${
        tableOrSubformField ? "custom-line-height" : "lh-sm"
      }`,
    };

    let upperLabel = <div>{displayItem.label}</div>;

    let lowerLabel = labelSubtext ? (
      <div className="fs-10pt fw-normal fst-italic text-muted">
        {labelSubtext}
      </div>
    ) : null;

    return (
      <Form.Label {...labelProps}>
        {upperLabel}
        {lowerLabel}
      </Form.Label>
    );
  };

  const smallInput =
    displayItem.widgetType == "textSmall" ||
    field.widgetType == "date" ||
    field.widgetType == "partialDate";

  const partialDateField = smallInput && field.type == "partialDate";
  const dateField = smallInput && field.type == "date";

  // Helper function to parse unit strings and replace ^ with <sup> and _ with <sub>
  const parseUnit = (unit) => {
    if (!unit) return null;

    const unitElements = [];
    let i = 0;

    while (i < unit.length) {
      // handle superscript
      if (unit[i] === "^") {
        i++;
        let supNum = "";
        while (i < unit.length && /[0-9-]/.test(unit[i])) {
          // gather the digits after '^'
          supNum += unit[i];
          i++;
        }
        unitElements.push(<sup key={unitElements.length}>{supNum}</sup>);
        // handle subscript
      } else if (unit[i] === "_") {
        i++;
        let subNum = "";
        while (i < unit.length && /[0-9]/.test(unit[i])) {
          subNum += unit[i];
          i++;
        }
        unitElements.push(<sub key={unitElements.length}>{subNum}</sub>);
        // handle any other letters
      } else {
        unitElements.push(<span key={unitElements.length}>{unit[i]}</span>);
        i++;
      }
    }

    return unitElements;
  };

  // Build our form input
  const formControl = (extraClasses = []) => {
    let formControlClasses = [];

    if (!readonly) {
      formControlClasses.push("px-2"); // Add px-2 only if not readonly
    }
    formControlClasses.push("py-1");
    formControlClasses = formControlClasses.concat(extraClasses);

    if (tableOrSubformField && !smallInput) {
      formControlClasses.push("min-width-string-field");
    }

    let value;

    if (typeof fieldData.value === "number") {
      value = fieldData.value;
    } else {
      value = fieldData.value || (readonly ? "[left blank]" : "");
    }

    let displayValue =
      fieldData.value !== undefined && fieldData.value !== null
        ? fieldData.value.toString()
        : "";

    let unitElement = null;
    let stringFormatElement = null;

    if (readonly && unit && displayValue.trim() !== "") {
      unitElement = (
        <span className={`field-unit-${field.identifier} ms-1`}>
          {parseUnit(unit)}
        </span>
      );
    }

    if (readonly && stringFormat && displayValue.trim() !== "") {
      stringFormatElement = (
        <div className="text-muted fs-6 mt-1">{stringFormat}</div>
      );
    }

    let dynamicPlaceholder = readonly ? null : placeholder;

    if (tableOrSubformField && field.type === "decimal" && fieldPostText) {
      dynamicPlaceholder = `${field.precision} dp`;
    }

    let formControlProps = {
      type: "text",
      value,
      name: fieldName,
      onChange: (e) => {
        onChangeHandler(e);
      },
      isInvalid: errors.length > 0,
      placeholder: dynamicPlaceholder,
    };

    // Most barcode scanners work by typing in the code and then simulating an enter keypress
    // We don't want this to submit the form, so can disable the default enter behaviour if specified
    const preventFormSubmission = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
      }
    };
    if (preventSubmitOnEnter) {
      formControlProps["onKeyDown"] = preventFormSubmission;
    }

    if (displayInline) {
      formControlProps["sm"] = "auto";
    } else {
      formControlProps["sm"] = 8;
    }

    if (displayItem.widgetType == "textArea") {
      formControlProps["as"] = "textarea";
      formControlProps["rows"] = 5;
      formControlClasses.push("lh-sm min-width-200");
      if (inputContext == "subform") {
        formControlClasses.push("w-auto lh-sm");
      }
    }

    if (smallInput) {
      formControlClasses.push("small-field");
    }

    if (readonly) {
      // Render as a div for readonly fields instead of a Form.Control
      const displayValue =
        fieldData.value !== undefined && fieldData.value !== null
          ? fieldData.value
          : "[left blank]";

      if (displayItem.widgetType === "textArea") {
        return (
          <div
            className={`display-linebreak ${
              !fieldData.value && fieldData.value !== 0 ? "text-muted" : ""
            } custom-line-height-for-display-items`}
            data-field-name={fieldName}
          >
            {displayValue}
          </div>
        );
      } else if (displayItem.widgetType === "text") {
        //For string fields
        return (
          <div
            className={`d-block custom-line-height-for-display-items string-field ${
              !fieldData.value && fieldData.value !== 0 ? "text-muted" : ""
            }`}
            data-field-name={fieldName}
          >
            {displayValue}
          </div>
        );
      } else {
        return (
          <div
            className={`d-inline ${
              !fieldData.value && fieldData.value !== 0 ? "text-muted" : ""
            }`}
            data-field-name={fieldName}
          >
            {displayValue}
          </div>
        );
      }
    }
    if (formControlClasses.length > 0) {
      formControlProps["className"] = formControlClasses.join(" ");
    }

    return <Form.Control {...formControlProps}></Form.Control>;
  };

  // Build our error messages
  const errorFeedback = (
    <Form.Control.Feedback className="error-message" type="invalid">
      {errors.join(", ")}
    </Form.Control.Feedback>
  );

  const tooltipData = (placeholer) => {
    switch (placeholder) {
      case "{dd}/mm/yyyy":
        return "Month and year are required, day is optional (but should be entered if known)";
      case "{dd}/{mm}/yyyy":
        return "Year is required, day and month are optional (but should be entered if known)";
      case "mm/yyyy":
        return "Month and year are required";
      case "{mm}/yyyy":
        return "Year is required, month is optional (but should be entered if known)";
      default:
        return null;
    }
  };

  const shouldAlignCenter = !errors.length && !stringFormat && readonly;

  if (inlineField) {
    return (
      <Col sm={"auto"}>
        <Form.Group
          controlId={fieldName}
          as={Row}
          className={`custom-mb-12 ${
            readonly
              ? "d-flex align-items-baseline"
              : displayItem.widgetType === "textArea" // for the text area as it spans across multiple lines, we want the label to align with the first row of the text area
              ? "d-flex align-items-start"
              : "d-flex align-items-baseline"
          }`}
          id={fieldID}
        >
          {formLabel()}
          <Col className="pe-0">
            {formControl("d-inline")}
            {parseUnit(unit) ? (
              <div
                className={`field-unit-${field.identifier} fs-6 d-inline ms-2`}
              >
                {parseUnit(unit)}
              </div>
            ) : null}
            {fieldPostText ? (
              <div className="fs-10pt custom-line-height fst-italic text-muted d-inline ms-2">
                {fieldPostText}
              </div>
            ) : null}
            <RevisionInfo fieldData={fieldData}></RevisionInfo>
            {errorFeedback}
            {stringFormat ? (
              <div
                className={`string-format-${field.identifier} custom-mb-12 text-muted fs-6 mt-1`}
              >
                {stringFormat}
              </div>
            ) : null}
          </Col>
          {field.hint && (
            <div
              className={`field-hint custom-line-height field-hint-${
                field.identifier
              } ${
                tableOrSubformField ? "hint-table-subform-context" : ""
              } custom-mb-12 text-muted fst-italic`}
            >
              {field.hint}
            </div>
          )}
        </Form.Group>
      </Col>
    );
  } else if (tableOrSubformField) {
    return (
      <div className="table-or-subform-context">
        <Col sm={"auto"}>
          <Form.Group
            controlId={fieldName}
            as={Row}
            className={`
              d-flex 
              ${
                readonly
                  ? "align-items-baseline"
                  : displayItem.widgetType === "textArea" // for the text area as it spans across multiple lines, we want the label to align with the first row of the text area
                  ? "align-items-start"
                  : "align-items-baseline"
              } 
              ${!field.hint ? "custom-mb-12" : ""}
            `}
            id={fieldID}
          >
            {formLabel()}
            <Col>
              <span
                data-tooltip-id={partialDateField ? "my-tooltip" : null}
                data-tooltip-content={
                  partialDateField && !readonly
                    ? tooltipData(placeholder)
                    : null
                }
              >
                {/* Dynamically apply either "partial-date" or "full-date" class */}
                {formControl(
                  partialDateField
                    ? ["d-inline", "partial-date"]
                    : dateField
                    ? ["d-inline", "full-date"]
                    : ["d-inline"]
                )}
                <Tooltip
                  id="my-tooltip"
                  className="z-index-999"
                  place="right-end"
                />
              </span>
              {/* parseUnit */}
              {parseUnit(unit) ? (
                <div
                  className={`field-unit-${field.identifier} fs-6 d-inline ms-1`}
                >
                  {parseUnit(unit)}
                </div>
              ) : null}
              {/* fieldPostText */}
              {fieldPostText && field.type !== "decimal" ? (
                <div
                  className={`fs-10pt custom-line-height fst-italic text-muted ${
                    inputContext === "table" || inputContext === "subform"
                      ? "d-block mt-1" // Display on a new line
                      : "d-inline ms-1" // Keep inline
                  }`}
                >
                  {fieldPostText}
                </div>
              ) : null}
              <RevisionInfo fieldData={fieldData}></RevisionInfo>
              {errorFeedback}
              {stringFormat ? (
                <div
                  className={`string-format-${field.identifier} string-format-table-subform-context custom-mb-12 text-muted fs-6 mt-1`}
                >
                  {stringFormat}
                </div>
              ) : null}
            </Col>
            {field.hint && (
              <div
                className={`field-hint custom-line-height field-hint-${
                  field.identifier
                } ${
                  tableOrSubformField ? "hint-table-subform-context" : ""
                } custom-mb-12 text-muted fst-italic`}
              >
                {field.hint}
              </div>
            )}
          </Form.Group>
        </Col>
      </div>
    );
  } else {
    return (
      <Form.Group
        controlId={fieldName}
        as={Row}
        className={`${
          field.hint || stringFormat ? "" : "custom-mb-12"
        } d-flex ${
          readonly
            ? "align-items-baseline"
            : displayItem.widgetType === "textArea" // for the text area as it spans across multiple lines, we want the label to align with the first row of the text area
            ? "align-items-start"
            : "align-items-baseline"
        }`}
        id={fieldID}
      >
        {formLabel()}
        <Col>
          <span
            data-tooltip-id={partialDateField ? "my-tooltip" : null}
            data-tooltip-content={
              partialDateField && !readonly ? tooltipData(placeholder) : null
            }
          >
            {formControl("d-inline")}
            <Tooltip
              id="my-tooltip"
              className="z-index-999"
              place="right-end"
            />
          </span>

          {unit && (
            <div
              className={`field-unit-${field.identifier} fs-6 d-inline ms-2`}
            >
              {parseUnit(unit)}
            </div>
          )}

          {fieldPostText ? (
            <div className="fs-10pt custom-line-height fst-italic text-muted d-inline ms-2">
              {fieldPostText}
            </div>
          ) : null}

          <RevisionInfo fieldData={fieldData}></RevisionInfo>
          {errorFeedback}
          {stringFormat && (
            <div
              className={`string-format-${field.identifier} custom-mb-12 text-muted fs-14px mt-1`}
            >
              {stringFormat}
            </div>
          )}
        </Col>
        {field.hint && (
          <div
            className={`field-hint custom-line-height field-hint-${
              field.identifier
            } ${
              tableOrSubformField ? "hint-table-subform-context" : ""
            } custom-mb-12 text-muted fst-italic`}
          >
            {field.hint}
          </div>
        )}
      </Form.Group>
    );
  }
};

export default TextInput;
