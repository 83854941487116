import React from "react";

import { Form, Button, Table, ButtonGroup } from "react-bootstrap";

import { buildDisplayItems } from "../../../services/display_item_helper";
import { InputContext } from "../../../contexts";

import Card from "react-bootstrap/Card";

const VerticalSubform = ({
  subform,
  buildDeleteButton,
  buildNewButton,
  buildReorderButtons,
  displayItem,
  readonly,
  buildHiddenFields,
  buildRowContext,
}) => {
  let row_num = 0;

  const rows = subform.subformData.map((row, index) => {
    const rowContent = (
      <Card
        className={`rounded border ${
          row.marked_for_delete ? "border-2 border-danger" : "border-1"
        } mt-3 bg-white`}
        id={`${subform.pluralizedIdentifier}_${index}`}
      >
        <Card.Header className="d-flex justify-content-between align-items-center">
          {/* Left side: Entry number */}
          <Card.Title className="fs-5 px-1 fw-bold mb-0">
            Entry {index + 1}
          </Card.Title>

          {/* Right side: Buttons + Delete message */}
          <div className="d-flex flex-column align-items-end">
            {/* Buttons */}
            {!readonly && (
              <div className="d-flex">
                {buildHiddenFields(row, index, row_num++)}
                {buildDeleteButton(row, index)}
                {buildReorderButtons(index)}
              </div>
            )}

            {/* Delete message below buttons */}
            {row.marked_for_delete && (
              <div className="text-black small text-wrap custom-line-height-one-point-two mt-3">
                This entry will be deleted when the form is saved
              </div>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          {buildDisplayItems(displayItem.children)}
          <div className="text-danger text-center">
            {row.base_errors.map((error, index) => {
              return <div key={index}>{error}</div>;
            })}
          </div>
        </Card.Body>
      </Card>
    );

    return buildRowContext(index, row, rowContent);
  });

  return (
    <InputContext.Provider
      value={{
        inputContext: null,
      }}
    >
      {/* fieldset containing subform name */}
      <fieldset
        className="prospect-fieldset rounded border border-1 pt-3 pb-2 bg-white mb-3"
        id={displayItem.subformIdentifier}
      >
        <legend className="fs-5 px-1 fw-bold rounded border border-1">
          {displayItem.subformName}
        </legend>
        {rows.length == 0 ? (
          <div className="text-center">This subform contains no data</div>
        ) : (
          rows
        )}
        <div className="d-flex justify-content-end">{buildNewButton()}</div>
      </fieldset>
    </InputContext.Provider>
  );
};

export default VerticalSubform;
