import React from "react";
import { useContext } from "react";
import { FormContext } from "../../contexts";

const RevisionInfo = ({ fieldData }) => {
  const { auditPage } = useContext(FormContext);

  if (!auditPage) {
    return null;
  }

  // Helper function to check if multiple select fields have the same values
  const arraysContainSameElements = (arr1, arr2) => {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
    if (arr1.length !== arr2.length) return false;
    return [...arr1]
      .sort()
      .every((item, index) => item === [...arr2].sort()[index]);
  };

  const PreviousValue = () => {
    if (fieldData.previous_form_deleted) {
      return <div className="text-danger">Previous Value: [form deleted]</div>;
    }

    if (
      (Array.isArray(fieldData.previous_value) &&
        Array.isArray(fieldData.value) &&
        arraysContainSameElements(fieldData.previous_value, fieldData.value)) ||
      (!Array.isArray(fieldData.previous_value) &&
        fieldData.previous_value == fieldData.value) ||
      !fieldData.previous_form_exists
    ) {
      return null;
    }

    let displayValue;
    if (Array.isArray(fieldData.previous_value)) {
      if (fieldData.previous_value.length > 0) {
        displayValue = fieldData.previous_value.reverse().join(", ");
      } else {
        displayValue = "[left blank]";
      }
    } else if (!fieldData.previous_value) {
      displayValue = "[left blank]";
    } else {
      displayValue = fieldData.previous_value.toString();
    }

    return <div className="text-danger">Previous Value: {displayValue}</div>;
  };

  const CurrentValue = () => {
    if (fieldData.current_form_deleted) {
      return <div>Current Value: [form deleted]</div>;
    }

    // Check if the value has not changed or if the form doesn't exist
    if (
      (Array.isArray(fieldData.current_value) &&
        Array.isArray(fieldData.value) &&
        arraysContainSameElements(fieldData.current_value, fieldData.value)) ||
      fieldData.current_value == fieldData.value ||
      !fieldData.current_form_exists
    ) {
      return null; // No change, so don't display
    }

    let displayValue;

    if (Array.isArray(fieldData.current_value)) {
      if (fieldData.current_value.length > 0) {
        displayValue = fieldData.current_value.reverse().join(", ");
      } else {
        displayValue = "[left blank]";
      }
    } else if (!fieldData.current_value) {
      displayValue = "[left blank]";
    } else {
      displayValue = fieldData.current_value.toString();
    }

    return <div>Current Value: {displayValue}</div>;
  };

  return (
    <div className="text-success">
      {PreviousValue()}
      {CurrentValue()}
    </div>
  );
};

export default RevisionInfo;
