import { createContext } from "react";

// The overall context about the form that doesn't change in tables, subforms, etc
const FormContext = createContext({
  readonly: undefined,
  fields: undefined,
  subforms: undefined,
  lookups: undefined,
  siteMetaLookups: undefined,
  studyLists: undefined,
  tables: undefined,
  generalErrors: undefined,
  persisted: undefined,
});

export default FormContext;
