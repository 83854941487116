import React from "react";
import { useContext } from "react";
import { FieldDataContext, FormContext } from "../../../contexts";
import { Alert } from "react-bootstrap";

const ProspectDisplayItem = ({ displayItem }) => {
  const { formValues } = useContext(FieldDataContext);
  const { is_history_page } = useContext(FormContext);

  // Get a reference to our field data
  const fieldData = formValues[displayItem.fieldIdentifier];

  if (!fieldData.dependency_met) {
    return null;
  }

  if (is_history_page) {
    return null;
  }

  switch (displayItem.widgetType) {
    case "heading": {
      return (
        <p className="fw-bold fs-6 custom-line-height-for-display-items custom-mb-12">
          {displayItem.label}
        </p>
      );
    }
    case "paragraph": {
      return (
        <p className="custom-line-height-for-display-items custom-mb-12">
          {displayItem.label}
        </p>
      );
    }
    case "grey_alert": {
      return (
        <Alert variant="secondary" className="px-2 py-1 col-8 custom-mb-12">
          <i className="far fa-exclamation-triangle me-2"></i>
          {displayItem.label}
        </Alert>
      );
    }
    case "orange_alert": {
      return (
        <Alert variant="warning" className="px-2 py-1 col-8 custom-mb-12">
          <i className="far fa-exclamation-triangle me-2"></i>
          {displayItem.label}
        </Alert>
      );
    }
    case "red_alert": {
      return (
        <Alert variant="danger" className="px-2 py-1 col-8 custom-mb-12">
          <i className="far fa-exclamation-triangle me-2"></i>
          {displayItem.label}
        </Alert>
      );
    }
    case "bulletpoint": {
      return (
        <ul>
          <li className="custom-line-height-for-display-items custom-mb-12">
            {displayItem.label}
          </li>
        </ul>
      );
    }
  }

  return null;
};

export default ProspectDisplayItem;
