import React, { useState, useEffect } from "react";

const GoToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Toggle visibility of the button based on scroll position
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > window.innerHeight * 0.5); // Show the button after scroll half the window height
    };

    // Add event listener for scroll events
    window.addEventListener("scroll", toggleVisibility);

    // Call it initially to check visibility on mount
    toggleVisibility();

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []); // Empty dependency array ensures this runs only once (on mount)

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    isVisible && (
      <button onClick={handleScrollToTop} className="scroll-to-top">
        <i className="fa fa-angle-up" />
      </button>
    )
  );
};

export default GoToTopButton;
