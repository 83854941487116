import React from "react";

import { buildDisplayItems } from "../../../services/display_item_helper";

const RowLabel = ({ displayItem }) => {
  return (
    <div className="col-sm-auto fw-bold custom-line-height">
      {displayItem.label}
    </div>
  );
};

export default RowLabel;
